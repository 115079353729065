import 'rc-checkbox/assets/index.css'
import RcCheckbox from 'rc-checkbox'
import React from 'react'
import check from './img/check.svg'
import fillCheck from './img/fillCheck.svg'
import styled, { css } from 'styled-components'
import { color } from '../../styles/vars/colors'
import { omit } from 'rambda'

const checkedNoFillCSS = ({ checked, fill }) =>
  checked &&
  !fill &&
  css`
    .rc-checkbox-inner::after {
      content: url(${check});
      animation: unset !important;
    }

    .rc-checkbox-inner {
      border-color: ${color.formItem.miniRadio.checked} !important;
    }

    .rc-checkbox:focus-within {
      .rc-checkbox-inner {
        border: 1px solid ${color.formItem.miniRadio.checked} !important;
      }
    }

    &:hover {
      border-color: transparent;

      .rc-checkbox-inner {
        border: 1px solid ${color.formItem.miniRadio.checked} !important;
      }
    }

    &,
    &:active .rc-checkbox-inner {
      background-color: none !important;
    }
  `

const checkedFillCSS = ({ checked, fill }) =>
  checked &&
  fill &&
  css`
    .rc-checkbox-inner::after {
      content: url(${fillCheck});
      box-sizing: border-box;
      border-radius: 4px;
      animation: unset !important;
      z-index: 3;
      background: ${color.formItem.miniRadio.checked};
      border: 1px solid ${color.formItem.miniRadio.checked};
    }

    .rc-checkbox-inner {
      border-color: ${color.formItem.miniRadio.checked} !important;
    }

    .rc-checkbox:focus-within {
      .rc-checkbox-inner {
        border: 1px solid ${color.formItem.miniRadio.checked} !important;
      }
    }

    &:hover {
      border-color: ${color.formItem.miniRadio.hover} !important;

      .rc-checkbox-inner::after {
        background: ${color.formItem.miniRadio.hover};
        border: 1px solid ${color.formItem.miniRadio.hover};
      }
    }

    &:active {
      border-color: ${color.formItem.miniRadio.press} !important;

      .rc-checkbox-inner::after {
        background: ${color.formItem.miniRadio.press};
        border: 1px solid ${color.formItem.miniRadio.press};
      }
    }

    &:not(:active) .rc-checkbox-inner {
      background-color: unset;
    }
  `

const disabledCSS = ({ disabled }) =>
  disabled &&
  css`
    &,
    &:hover {
      pointer-events: none;

      .rc-checkbox-inner {
        border-color: ${color.checkbox.disabled} !important;
      }
    }
  `

export const CheckboxMini = styled(RcCheckbox).attrs(({ value, checked }) => ({
  value,
  checked
}))`
  input {
    width: 18px;
    height: 18px;
  }

  .rc-checkbox:hover .rc-checkbox-inner,
  .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: #d9d9d9;
  }

  &:hover {
    border: unset;

    .rc-checkbox-inner {
      border: 1px solid #9d9d9d !important;
    }
  }

  .rc-checkbox-inner {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: ${({ fill }) => !fill && 'unset !important'};
  }

  &:active .rc-checkbox-inner {
    background-color: ${({ fill }) =>
      fill && String(color.formItem.miniRadio.noCheckedPress)};
  }

  .rc-checkbox-inner::after {
    content: '';
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    top: -1px;
    left: -1px;
    transform: rotate(0deg);
    border: 1px solid transparent;
  }

  ${checkedFillCSS}
  ${checkedNoFillCSS}
  ${disabledCSS}
`

export const CheckboxMiniWithLabel = styled(
  ({ className, label, ...field }) => (
    <div className={className}>
      <CheckboxMini {...omit(['error', 'onFocus'], field)} />
      {label && <label htmlFor={field.id}>{label}</label>}
    </div>
  )
)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;

  & > label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${color.checkbox.disabled};
      `}
  }
`
