import React, { useCallback, useRef, useState } from 'react'
import Select from 'rc-select'
import styled, { css } from 'styled-components'
import { CheckboxMini } from '../CheckboxMini'
import { Field } from 'react-final-form'
import { IconArrowDown } from '../../Icons'
import { RadioMini } from '../RadioMini'
import { Text } from '../../Text'
import { color } from '../../../styles/vars/colors'
import { gradientTextCSS, selectStyles } from './styles'
import { omit, pick } from 'rambda'
import { size } from '../../../constants'
import { useClickOutside } from '../../useClickOutside'

export const StyledSelect = styled(Select)`
  ${selectStyles}
`

const OptionDescription = styled.span`
  margin-left: 8px;
  color: ${color.formItem.select.option.description};
`

const OptionText = ({ text, description }) => (
  <Text.Average>
    {text}
    {description && <OptionDescription>{description}</OptionDescription>}
  </Text.Average>
)

const SelectItem = styled(
  ({ className, text, description, optionType, value, ...field }) => (
    <Field name={field.name} type={optionType} value={value}>
      {({ input }) => (
        <label className={className} htmlFor={value}>
          {optionType === 'checkbox' ? (
            <CheckboxMini
              fill
              id={value}
              name={value}
              type={optionType}
              {...omit(['error', 'onFocus'], input)}
            />
          ) : optionType === 'radio' ? (
            <RadioMini
              id={value}
              name={value}
              type={optionType}
              {...omit(['error', 'onFocus'], input)}
            />
          ) : null}
          <OptionText description={description} text={text} />
        </label>
      )}
    </Field>
  )
)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 4;
  padding: 10px;

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 14px;
    `}

  &:hover {
    background: ${color.formItem.select.dropdown.hover};
  }

  &:active {
    background: ${color.formItem.select.dropdown.press};
  }

  & > ${Text.Average} {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    width: 100%;
    ${({ description }) => !description && String(gradientTextCSS)}
  }
`

const Dropdown = styled(({ className, options, optionType, ...field }) => (
  <div className={className}>
    {options.map((option) => (
      <SelectItem
        key={option.value}
        optionType={optionType}
        {...option}
        {...field}
      />
    ))}
  </div>
))`
  padding: 4px 0;
  background: ${color.background};
  box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.06),
    0px 0.788403px 17.869px rgba(0, 0, 0, 0.0357664),
    0px 0.117173px 5.32008px rgba(0, 0, 0, 0.0242336);
  border-radius: 4px;
  max-height: ${({ virtual }) => !virtual && '328px !important'};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 3px;
    height: 131px;
  }
`

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & ${Input} {
        height: 40px;
      }

      & ${Arrow} {
        right: 10px;
        bottom: 10px;
      }

      &::after {
        padding: 10px;
      }
    `,
    large: css`
      & ${Input} {
        height: 48px;
        padding: 14px;
        padding-right: 32px;
      }

      & ${Arrow} {
        right: 14px;
        bottom: 14px;
      }

      &::after {
        padding: 14px;
      }
    `
  }[size])

const errorCSS = ({ error }) =>
  error &&
  css`
    &:hover,
    &:active {
      &::before {
        z-index: -1;
      }
    }

    & input {
      border-color: ${color.formItem.border.error};

      &:hover,
      &:active {
        border-color: ${color.formItem.border.error};
      }

      &:hover {
        background: ${color.formItem.background.errorHover};
      }

      &:focus {
        border-color: ${color.formItem.border.error};
      }
    }
  `

const resizeCSS = ({ noresize }) =>
  !noresize &&
  css`
    @media (max-width: ${size.sm}) {
      max-height: 48px;

      & ${Input} {
        padding: 14px;
        height: 48px;
      }

      &::after {
        padding: 14px;
      }
    }
  `

const Arrow = styled(IconArrowDown)`
  ${({ visible }) =>
    visible &&
    css`
      transform: rotate(180deg);
    `}
`

const Input = styled.input`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${color.formItem.text.default};
  border-radius: 4px;
  padding-right: 32px !important;
  outline: none;
  border: 1px solid ${color.formItem.border.default};
  height: 40px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 48px inset #fff !important;
    -webkit-box-shadow: 0 0 0 48px inset #fff !important;
  }

  &::placeholder {
    color: ${color.formItem.placeholder};
  }

  &:hover {
    border-color: ${color.formItem.border.hover};
  }

  &:focus {
    border-color: ${color.formItem.border.focus};
  }

  &::placeholder {
    color: ${color.formItem.placeholder};
  }

  &:disabled {
    background: ${color.formItem.background.disabled};
    border-color: ${color.formItem.background.disabled};
  }

  ${({ success }) =>
    success &&
    css`
      border-color: #03b2a5 !important;
    `}

  ${({ focus }) =>
    focus &&
    css`
      border-color: ${color.formItem.border.focus} !important;
    `}
`

const getRenderingValue = ({ value, multipliePrefix, optionType, options }) => {
  const filteredOptions = options.filter((option) =>
    optionType === 'radio'
      ? option.value === value
      : value.includes(option.value)
  )

  if (optionType === 'radio') return filteredOptions[0]?.text
  return filteredOptions.length > 1
    ? `${multipliePrefix} (${filteredOptions.length})`
    : filteredOptions.length === 1
    ? filteredOptions[0]?.text
    : ''
}

const useDropdownVisible = () => {
  const refInput = useRef()
  const ref = useRef()
  const [visible, setVisible] = useState()

  useClickOutside({
    ref,
    hide: useCallback(() => {
      setVisible(false)
    }, []),
    visible
  })

  return {
    ref,
    refInput,
    visible,
    toggle: useCallback(() => {
      setVisible(!visible)
    }, [visible])
  }
}

export const InputSelectRadioOrCheckboxes = styled(
  ({ className, multipliePrefix, optionType, options, ...field }) => {
    const { ref, refInput, toggle, visible } = useDropdownVisible()
    return (
      <div ref={ref} className={className}>
        <Input
          ref={refInput}
          readOnly
          focus={visible}
          {...omit(['error', 'success', 'onFocus'], field)}
          onClick={toggle}
          value={getRenderingValue({
            value: field.value,
            multipliePrefix,
            optionType,
            options
          })}
        />
        {visible && (
          <Dropdown
            optionType={optionType}
            options={options}
            {...pick(['size', 'name', 'virtual'], field)}
          />
        )}
        <Arrow visible={visible} />
      </div>
    )
  }
)`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;

  ${Arrow} {
    position: absolute;
    right: 10px;
    bottom: 10px;
    transition: all 0.33s ease;
    z-index: 1;
    color: ${color.formItem.select.arrow.color};
  }

  ${Dropdown} {
    position: absolute;
    width: 100%;
    margin-top: 4px;
  }

  &:not(:focus-within) {
    &::before {
      content: '';
      cursor: pointer;
      pointer-events: none;
      position: absolute;
      right: 24px;
      z-index: 1;
      width: 2ch;
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      height: calc(100% - 4px);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0.6699054621848739) 100%
      );
    }
  }

  ${errorCSS}
  ${resizeCSS}
  ${sizeCSS}
`
